.addCategory .Related-containerss {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.addCategory .Related-containerss-row {
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 10px;
}
.addCategory .Related-containerss .ad-image {
  width: 100%;

  transition: transform 0.3s ease;
}
@media (min-width: 768px) {
  .addCategory .Related-containerss .Related-containerss-bottom-row img {
    aspect-ratio: 10/1 !important;
  }
}

.addCategory .Related-containerss .Related-containerss-row:last-child .ad-image {
  width: 100%;
}

.addCategory .Related-containerss .ad-item {
  margin: 5px 0;
}

@media (max-width: 768px) {
  .addCategory .Related-containerss {
    padding: 0;
    margin: 0;
    width: 100%;
  }
}
@media (max-width: 768px) {
  .Related-containerss-row {
    display: flex;
    flex-direction: column;
  }
  .addCategory {
    padding: 0 5vw !important;
  }
}
