.pagination-component-wrapper {
  display: flex;
  width: 100%;
  justify-content: center;
  justify-content: center;
}

.pagination-container {
  display: flex;
  width: max-content;
  justify-content: center;
  justify-content: center;
  margin-top: 20px;
  gap: 10px;
}
.page-button-index-active{
  justify-content: center;
  color: #142745;
  background: linear-gradient(to bottom right, red, darkred) !important;
}
.page-button-index-{
  background-color: white !important;
}
.pagination-container button {
  all: unset;
  display: flex;
  align-self: center;
  border: 1px solid #ccc;
  padding: 10px 15px;
  cursor: pointer;
  transition: background-color 0.3s;
  background-color: white;
}
.pagination-container button:hover{
  all:unset;
  display: flex;
  align-self: center;
  border: 1px solid #ccc;
  padding: 10px 15px;
  cursor: pointer;
  transition: background-color 0.3s;
  background-color: white !important;

}