.Related-Left {
  display: flex;
  align-items: start;
  justify-content: start;
  margin: 15px 11vw;
  gap: 15px;
}
.Related-header-kıbrıslı-logo{
  width:80%;
  padding-top:2rem;
}
.Related-Left-ad {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: start;
  flex-direction: column;
  gap: 15px;
  padding: 10px;
}

.Related-Left-content {
  margin-top: 20px;
}
.Related-Left-h1 {
  font-family: Inter;
  font-size: 30px;
  font-weight: 600;
  line-height: 36.31px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}
.Related-Left-text {
  font-family: Inter;
  font-size: 18px;
  font-weight: 400;
  line-height: 21.78px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;

  font-size: 1rem;
  color: #121212;
}

@media (max-width: 1000px) {
  .Related-Left {
    margin: 15px 5vw;
  }
}

@media (max-width: 768px) {
  .Related-Left {
    display: grid;
    grid-template-columns: 1fr;
    margin: 15px 5vw;
  }
  .Related-Left-ad {
    padding: 0px;
  }.Related-side{
    width:100% !important;
  }
}

.Related-header {
  width: 100%;
  height: auto;
  min-height: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}

.Related-header-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin-top: 25px;
  text-wrap: wrap;
}
.Related-header-info p {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 25px;
  text-wrap: wrap;
  text-align: center;
  font-family: Inter;
  font-weight: 600;
  font-size: 18px;
  line-height: 100%;
  letter-spacing: 0%;
  text-align: center;
}
.Related-side{
  width:50%;
}