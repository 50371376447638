.maincentered {
  display: grid;
  align-items: center;
  margin-top: 12px; /* Tailwind'de mt-3 = 12px */
}

.centered {
  display: flex;
}

.flex {
  display: flex;
  align-items: center;
  justify-content: center;
}

.color {
  margin: 0;
  color: white;
}
.icon {
  height: 30px;
  width: 30px;
  margin: 0;
  padding: 0;
}
