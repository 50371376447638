/* CategoryPage.css */
.category-page-titleBox {
  padding: 0 12vw;
}
.article-card {
  height: 100%;
  position: relative;
}
.article-image {
  min-height: 80%;
}
/* Genel Kategoriler Sayfası */
.category-page {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 0 12vw;
}
.catgeoryAd {
  flex: 7;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center; /* İçeriği yatayda ortalar */
  text-align: left; /* Metni ortalar */
  margin: 0 20px 0 0; /* Ortalamak için */
}

/* Başlık ve Kategori Resmi */
.category-header {
  flex: 3;
  padding: 20px;
  background-color: #f4f4f4;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.category-title {
  font-size: 32px;
  font-weight: bold;
  color: #333;
  margin-bottom: 20px;
}

.category-image {
  width: 100%;
  height: auto;
  object-fit: cover;
}

/* Makaleler */
.articles {
  flex: 2;
  width: 100%;
  margin-top: 20px;
}

.articles ul {
  list-style-type: none;
  padding: 0;
}

.article {
  background-color: #fff;
  padding: 15px;
  margin-bottom: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.article-title {
  font-size: 22px;
  color: #333;
  height:20%;
  margin-bottom: 10px;
}

.article p {
  font-size: 16px;
  color: #555;
}

.article-image {
  width: 100%;
  height: 80%;
  /* margin-top: 15px; */
  object-fit: cover;
}

/* Side (Yan Panel) */
.Sidevar {
  flex: 1;
  border-radius: 10px;
}
@media (max-width: 960px) {
  .Sidevar {
    margin-top: 20px;
  }
}

.Table1Wrapper,
.Table2Wrapper {
  margin-bottom: 30px;
}

h2 {
  font-size: 24px;
  font-weight: bold;
  color: #333;
  margin-bottom: 15px;
}

button {
  align-items: center;
  justify-content: flex-start;
  padding: 10px;
  background-color: #fff;
  border: 1px solid #ddd;
  width: 100%;
  transition: all 0.3s ease;
}

button:hover {
  background-color: #b40505;
}

button img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 15px;
}

button div {
  flex: 1;
}

button div h3 {
  font-size: 18px;
  color: #333;
  margin-bottom: 5px;
}

button div p {
  font-size: 14px;
  color: #777;
}

/* Responsive tasarım: Ekran küçüldükçe kartlar alt alta sıralanır */
@media (max-width: 768px) {
  .category-page {
    display: grid;
    grid-template-columns: 1fr;
    gap: 15px;
  }
  .category-page-titleBox {
    padding: 0 5vw !important;
  }
  .article-title {
    font-size: 15px;
    text-align: left !important;
  }

  .articles-grid {
    display: grid;
    grid-template-columns: repeat(1, 1fr) !important; /* 2 sütun oluşturur */
    gap: 20px; /* Hücreler arasındaki boşluk */
    max-width: 100%; /* Max genişlik */
  }
  .catgeoryAd {
    margin: 0;
  }
}

.articles-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* 2 sütun oluşturur */
  gap: 10px; /* Hücreler arasındaki boşluk */
  max-width: 100%; /* Max genişlik */
}

.article-item {
  background-color: #f5f5f5;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}
h3 {
  font-size: 14px !important;
  text-align: left !important;
}
.manset-defaultImage {
  width: 100%;
}

@media (min-width: 767px) and (max-width: 928px) {
  .articles-grid {
    display: flex !important;
    flex-direction: column;
    width:90%;
  }
  .haber-details-side-wrapper{
    width:100%;
  }
  .category-page-titleBox {
    padding: 0 5vw !important;
  }
  .catgeoryAd{

    margin:0 !important
  }
  .articles{
    width:95%;
  }
}
@media (max-width: 1000px) {
  .category-page {
    padding: 0 5vw !important;
  }
}
