.Topbarbottom {
  display: flex;
  justify-content: start;
  background-color: #ff0000; /* Arka planı kırmızı yap */
  width: 100%;
}

.topbar-ad {
  display: flex;
  gap: 15px; /* Öğeler arasında boşluk */
  margin-left: 13vw !important;
}

.topbar-item {
  all:unset;
  display: flex;
  align-items: center; /* İçerikleri dikeyde ortala */
  background-color: #ff0000; /* Arka planı kırmızı yap */
  border-radius: 5px; /* Yuvarlatılmış köşeler */
}

.topbar-item:last-child .topbar-line-image {
  /*  display: noneSon itemde line'ı gizle */
}

.topbar-flex-center,.button-dropdown-link {
  display: flex;
  align-items: center;
  gap: 10px;
  color: white;
  font-weight: 700;
}
.topbar-item:hover {
  all:unset;
}
.topbar-item a ,.button-dropdown-link {
  text-decoration: none; /* Alt çizgiyi kaldır */
  color: white; /* Link rengini beyaz yap */
  font-size: 0.6rem; /* Font boyutu */
  font-weight: 700; /* Font ağırlığı */

}

.topbar-line-image {
  width: 10px;
  height: 35px;
  filter: invert(100%); /* Beyaza çevirir */
}

/* --- Duyarlı Tasarım --- */

@media (max-width: 1355px) {
  .topbar-ad {
    margin-left: 95px;
  }
}

@media (max-width: 1024px) {
  .Topbarbottom {
    justify-content: center; /* Orta hizalama */
  }

  .topbar-ad {
    margin-left: 0; /* Margin kaldır */
    gap: 10px; /* Daha küçük boşluklar */
  }

  .topbar-item {
    padding: 5px 10px; /* Daha küçük padding */
    font-size: 14px; /* Yazı boyutunu küçült */
  }

  .topbar-line-image {
    width: 8px; /* Line görsel boyutunu küçült */
    height: 25px; /* Yüksekliği küçült */
  }
}

@media (max-width: 768px) {
  .topbar-ad {
    flex-direction: column; /* Öğeleri dikey sırala */
    align-items: center; /* Öğeleri ortala */
    margin-left: 0;
  }

  .topbar-item {
    width: 100%; /* Tam genişlik */
    margin-bottom: 10px; /* Öğeler arası boşluk */
  }

  .topbar-line-image {
    display: none; /* Küçük ekranda line'ı gizle */
  }
}

.kibrisli-web {
  display: flex;
  align-items: center;
  justify-content: end;
  color: white;
  font: bold;
  gap: 8px;
}
.kibrisli-web a {
  display: flex;
  align-items: center;
  justify-content: end;
  color: white;
  list-style: none;
  text-decoration: none;
}
