.title-box {
  background: #142745;
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
  padding: 5px 10px;
  height: 40px;
  border-radius: 10px;
  color: white;
  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
  line-height: 16.94px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  width: fit-content;
  margin-top: 15px;
}

@media (max-width: 768px) {
  .title-box {
    padding: 1px 7px !important;
    height: 25px !important;
    font-size: 12px;
  }
}
