.Sidevar {
  box-sizing: border-box;
}

.Sidevar h2 {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 15px;
  color: white;
  background: linear-gradient(to bottom right, red, darkred);
  text-align: center;
  margin: 0;
  height: 40px;
  align-items: center;
  justify-content: center;
  display: flex;
}

.Sidevar ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  max-height: 320px;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: #ccc #fff;
}

.Sidevar li {
  margin-bottom: 0;
  border: 1px solid #ddd;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease;
}

.Sidevar li:hover {
  background-color: #f7f7f7;
}

.Sidevar button {
  display: flex;
  align-items: center;

  border: none;
  background: none;
  cursor: pointer;
  width: 100%;
  text-align: left;
  padding: 10px;
}

.Sidevar h3 {
  font-size: 16px;
  margin: 0;
}

.Sidevar p {
  font-size: 14px;
  color: #555;
  margin: 5px 0 0;
  gap: 10px;
}

.Table2Image {
  width: 100px;
  height: 60px;
  border-radius: 0px;
}

@media (max-width: 1170px) {
  .Table2Image {
    width: 90px; /* Yarı boyut */
    height: 50px; /* Yarı boyut */
  }
}

/* Sosyal medya başlığı için siyah renk */
.kibris-side-social-media-text {
  color: black; /* Siyah yazı rengi */
  font-family: Inter, sans-serif;
  font-size: 16px; /* Başlık boyutu */
  font-weight: 400; /* Başlık için kalın yazı */
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px !important;
}

.kibris-footer-social-media {
  margin: 0 !important;
}
.kibris-footer-social-media-icons {
  display: flex;
  justify-content: center;
}

.kibris-footer-social-icon {
  gap: 20px;
  color: white;
  transition: color 0.3s;
  background: linear-gradient(to bottom right, red, darkred);
  padding: 8px; /* İçerik için boşluk */
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px; /* İkonlar için yuvarlak boyut */
  height: 30px; /* İkonlar için yuvarlak boyut */
}

.kibris-footer-social-icon svg {
  color: white;
  width: 30px; /* İkon boyutunu küçült */
  height: 30px; /* İkon boyutunu küçült */
}
.Related-container-side {
  margin: 0 !important;
  height: 100%;
}

.Related-container-side img {
  aspect-ratio: 435/470 !important;
}

.ListItem2 h3 {
  display: -webkit-box;
  -webkit-line-clamp: 2; /* Sadece iki satır gösterilecek */
  -webkit-box-orient: vertical;
  overflow: hidden; /* Taşan kısmı gizler */
  text-overflow: ellipsis; /* Eğer metin iki satırdan uzun olursa üç nokta ekler */
  font-size: 16px;
  line-height: 1.4;
  text-align: left;
}

@media (max-width: 768px) {
  .Sidevar {
    box-sizing: border-box;
    width: 100%;
  }
  /* .Related-containerss img {
    width: 80vw;
    height: 300px;
    object-fit: strech;
  } */
}

/* @media (max-width: 983px) {
  .Related-containerss img {
    width: 100%;
    height: 33vh;
    object-fit: strech;
  }
} */
