.Side-Encokvar {
  box-sizing: border-box;
}

.Side-Encokvar h2 {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 15px;
  color: white;
  background: linear-gradient(to bottom right, red, darkred);
  text-align: center;
  height: 40px;
  align-items: center;
  justify-content: center;
  display: flex;
}

.Side-EncokScrollableList1 {
  list-style-type: none;
  padding: 0;
  margin: 0;
  max-height: 320px;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: #ccc #fff;
}

.Side-EncokListItem2 {
  border: 1px solid #ddd;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease;
}

.Side-EncokListItem2:hover {
  background-color: #f7f7f7;
}

.Side-EncokButton {
  display: flex;
  align-items: center;
  gap: 10px;
  border: none;
  background: none;
  cursor: pointer;
  width: 100%;
  text-align: left;
  padding: 10px;
}

.Side-EncokTable1Image {
  width: 90px;
  height: 57px;
  flex-shrink: 0;
  border-radius: 0px;
}

.Side-EncokContent h3 {
  font-size: 16px;
  margin: 0;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.4;
  text-align: left;
}

@media (max-width: 768px) {
  .Side-Encokvar {
    width: 100%;
  }
}
