.ziyaretci-aydinlatma-body {
  align-items: start;
  justify-content: start;
  margin: 0 12vw;
  gap: 15px;
}
.ziyaretci-aydinlatma-left-line {
  margin-top: 20px;
  width: 40%;
  border-bottom: 2px solid #ff0000;
}
.ziyaretci-aydinlatma-titleh3 {
  text-align: left;
  font-weight: 500;
}
.ziyaretci-aydinlatma-link {
  color: black;
}
.ziyaretci-aydinlatma-container {
  display: flex;
  gap:2rem;
}

@media (max-width: 768px) {
  .ziyaretci-aydinlatma-container {
    display: grid;
    grid-template-columns: 1fr; /* Tek sütun düzeni */
    gap: 20px;

  }
 .ziyaretci-aydinlatma-content {
    width: 100% !important; /* Genişliği %30 daraltarak %70'e düşürür */
  }

}
@media (max-width: 1000px) {
  .ziyaretci-aydinlatma-body {
    margin: 0 5vw;
  }
}
.ziyaretci-aydinlatma-content {
  width: 150%; /* Mobilde genişlik %150 */
  height:min-content
};
