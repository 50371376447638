.reporter-img {
  height: auto;
}

.reporter-img.large {
  width: 120px;
}

.reporter-img.small {
  width: 50px;
}

/* Media queries */
@media (max-width: 768px) {
  .reporter-img.large {
    width: 90px;
  }

  .reporter-img.small {
    width: 40px;
  }
}

@media (max-width: 480px) {
  .reporter-img.large {
    width: 60px;
  }

  .reporter-img.small {
    width: 30px;
  }
}
