/* iletisim.css */
.iletisim-titleBox {
  padding: 0 12vw;
}
.article-card {
  cursor: pointer;
}

.iletisim {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 0 12vw;
  gap:2rem;
}

.iletisim a {
  color: #333;
  text-decoration: none;
}
.iletisim-ad {
  flex: 7;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: left;
  margin: 0 auto;
}

.iletisim-header {
  flex: 3;
  padding: 20px;
  background-color: #f4f4f4;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.iletisim-title {
  font-size: 32px;
  font-weight: bold;
  color: #333;
  margin-bottom: 20px;
}

.iletisim-image {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.iletisim-articles {
  display: grid;
  width: 100%;
  margin-top: 20px;
  gap: 25px;
}
.iletisim-details-side-wrapper {
  flex: 3;
  border-radius: 10px;
}

.iletisim-articles-kunye {
  display: flex;
  width: 100%;
  gap: 20px;
}

@media (max-width: 768px) {
  .iletisim {
    display: grid;
    grid-template-columns: 1fr;
    gap: 15px;
    padding: 0 5vw !important;
  }
  .iletisim-form {
    width: 90%;
  }
  .iletisim-titleBox {
    padding: 0 5vw;
  }

  .iletisim-single-ad-component {
    padding: 0 3vw;
  }

  .iletisim-article-title {
    font-size: 15px;
    text-align: left !important;
  }
}

@media (max-width: 480px) {
  .iletisim-article {
    width: 100%;
  }
}
/* İletişim Bilgileri */
.iletisim-bilgileri-container {
  border: 2px solid #f00;
  padding: 20px;
  border-radius: 8px;

  flex: 1;
}

.iletisim-bilgileri-section {
  margin: 20px 0;
}

.iletisim-bilgileri-bold-font {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 5px;
  color: #333;
}

.iletisim-form-container {
  display: flex;
  flex-direction: column;
  gap: 15px;
  flex: 1;
}

.iletisim-input,
.iletisim-mesaj-input,
.iletisim-form-dropdown select {
  padding: 12px 15px;

  border-radius: 6px;
  border: 1px solid #ccc;
  font-size: 14px;
  transition: border 0.3s ease-in-out;
}

.iletisim-input:focus,
.iletisim-mesaj-input:focus,
.iletisim-form-dropdown select:focus {
  border-color: #f00;
  outline: none;
}

/* Mesaj alanı */
.iletisim-mesaj-input {
  padding: 15px;
  height: 150px; /* Yüksekliği artırdık */
  resize: vertical; /* Mesaj alanının boyutunu kullanıcıya göre ayarlayabilmesi için */
}

.iletisim-send-button {
  background-color: #f00;
  color: white;
  padding: 12px 25px;
  border-radius: 6px;
  border: none;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.iletisim-send-button:hover {
  background-color: #d30000;
}

/* İletişim Bilgileri */
.iletisim-bilgileri-container {
  border: 2px solid #f00;
  padding: 20px;
  border-radius: 8px;

  flex: 1;
}

.iletisim-bilgileri-section {
  margin: 20px 0;
}

.iletisim-bilgileri-bold-font {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 5px;
  color: #333;
}
.iletisim-side-left .dropdown-select {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.iletisim-form-dropdown {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}
dropdown-select {
  width: 100%;
}
@media screen and (max-width: 1290px) {
  .iletisim-articles-kunye {
    display: flex;
    flex-direction: column; /* flex-direction yazımı düzeltildi */
    width: 100%;
    gap: 20px;
  }

  .iletisim-form {
    width: 100%;
    margin: 0 auto;
  }

  .iletisim-articles-kunye-details {
    width: 90%;
    margin: 0 auto;
  }

  .iletisim-form-dropdown {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .iletisim-form-dropdown select {
    width: 100%;
  }

  .iletisim-articles-maps {
    width: 96%;
    margin: 0 auto;
  }
}
