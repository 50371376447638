.relatedItem {
  height: 100%;
  width: 100%;
  margin-top: 2vw;
}
.relatedLink {
  height: 100%;
  width: 100%;
}
.relatedImage {
  aspect-ratio: 6/1;
  width: 100%;
}
@media (min-width: 1000px) {
  .relatedItem {
    margin: 0.5vw 0;
  }
}
@media (max-width: 768px) {
  .relatedImage {
    aspect-ratio: 6/1;
    width: 100%;
  }
}
@media (min-width: 1600px) {
  .relatedImage {
    max-width: none;
  }
  .relatedItem {
    width: 100%;
  }
}
