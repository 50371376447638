.Socialmedia {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
}

.Socialmedia-header {
  width: 100%;
}

/* Menü toggle butonu */
.menu-toggle {
  font-size: 12px;
  height: 10px;
  width: 10px;
  cursor: pointer;
  background: linear-gradient(to bottom right, #ff0000, darkred);
  color: white;
  padding: 8px;
  border-radius: 50%;
  display: flex;
  transition: 0.3s;
  justify-content: center;
  align-items: center;
}

.menu-toggle:hover {
  background: darkred;
}

/* Slider - Sosyal medya ikonları ve kapatma butonu */
.Socialmedia-slider {
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
}

.social-icon {
  cursor: pointer;
  transition: transform 0.2s ease;
}

.social-icon:hover {
  transform: scale(1.1);
}

/* Kapatma butonu */
.Socialmedia-close-btn {
  cursor: pointer;
  width: 27px;
  height: 27px;
  background: linear-gradient(to bottom right, #ff0000, darkred);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
  position: relative; /*  İçerikle uyumlu hale getirme */
  margin-left: 10px; /* İkonlardan biraz ayrıştırma */
}

@media (max-width: 768px) {
  .menu-toggle {
    width: 20px;
    height: 20px;
    font-size: 18px;
    padding: 5px;
    text-align-last: center;
  }
  .social-icon {
    height: 28px;
    width: 28px;
    font-size: 16px;
  }
}
@media (max-width: 480px) {
  .menu-toggle {
    width: 15px;
    height: 15px;
    font-size: 14px;
    padding: 5px;
    text-align-last: center;
  }
  .Socialmedia-slider {
    gap: 5px;
  }
  .social-icon {
    font-size: 16px;
    margin-top: 10px;
  }
  .Socialmedia-close-btn {
    font-size: 14px;
    height: 28px;
    width: 25px;
    margin-bottom: -7px;
  }
}
