.eczane-page .eczane-left-container {
  display: flex !important;
  align-items: start !important;
  justify-content: start !important;
  margin: 15px 11vw !important;
  gap: 15px !important;
}

.eczane-left-ad-eczane-resmi-addeet {
  height: 100% !important;
  width: 100% !important;
  object-fit: fill !important;
}

.eczane-left-ad-eczane-resmi-addeet img {
  width: 100% !important;
  /* height: 284px; */
  object-fit: cover !important;
  aspect-ratio: 22/9 !important;
}

.eczane-Left-ad {
  width: 150% !important;
  height: 100% !important;
  display: flex !important;
  align-items: start !important;
  flex-direction: column !important;
  gap: 15px !important;
}

.eczane-Left-content {
  margin-top: 20px !important;
}
.eczane-Left-h1 {
  font-family: Inter !important;
  font-size: 30px !important;
  font-weight: 600 !important;
  line-height: 36.31px !important;
  text-align: left !important;
  text-underline-position: from-font !important;
  text-decoration-skip-ink: none !important;
}
.eczane-Left-text {
  font-family: Inter !important;
  font-size: 18px !important;
  font-weight: 400 !important;
  line-height: 21.78px !important;
  text-align: left !important;
  text-underline-position: from-font !important;
  text-decoration-skip-ink: none !important;

  font-size: 1rem;
  color: #121212 !important;
}

@media (max-width: 768px) {
  .eczane-left-container {
    display: grid !important;
    grid-template-columns: 1fr !important;
    margin: 15px 3vw !important;
  }
}

/*!
   * Bootstrap v4.3.1 (https://getbootstrap.com/)
   * Copyright 2011-2019 The Bootstrap Authors
   * Copyright 2011-2019 Twitter, Inc.
   * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
   */

ul {
  margin-top: 0;
  margin-bottom: 1rem;
}

a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
}

.row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  /* flex-wrap: wrap; */
  margin-right: -15px;
  margin-left: -15px;
}

.col-lg-3,
.col-lg-6,
.col-sm-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}
@media (min-width: 992px) {
  .col-lg-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-lg-6 {
    -ms-flex: 0 0 50%;
    /* flex: 0 0 50%; */
    max-width: 50%;
  }
}

@media (max-width: 540px) {
  .col-lg-3 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-lg-6 {
    -ms-flex: 0 0 80%;
    flex: 0 0 80%;
    max-width: 80%;
  }
  .row {
    display: -ms-flexbox;
    display: grid;
    margin-right: -15px;
    margin-left: -15px;
  }
}

.table td,
.table th {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
  height: fit-content;
}

.thead-dark {
  background-color: #343a40;
  color: white;
}
.my-2 {
  display: flex; /* İçerikleri yatay hizalamak için */
  gap: 8px; /* Span'lar arasına boşluk ekler */
  align-items: center; /* Dikey hizalamayı düzgün yapar */
}

.bg-info {
  background-color: #007bff !important; /* Mavi */
  color: white !important; /* Beyaz metin */
  padding: 4px 12px; /* px-2 py-1 */
  border-radius: 8px; /* Yuvarlak köşeler */
  font-weight: bold;
}

.bg-secondary {
  background-color: #6c757d !important; /* Gri */
  color: white !important; /* Beyaz metin */
  padding: 4px 12px; /* px-2 py-1 */
  border-radius: 8px; /* Yuvarlak köşeler */
  font-weight: bold;
}
.table.table-striped.mt-2 td,
.table.table-striped.mt-2 th {
  width: 1000px !important;
}
@media (max-width: 1000px) {
  .eczane-Left {
    margin: 15px 4vw !important;
  }
}
@media (max-width: 1000px) {
  .eczane-Left-ad {
    width: 100% !important;
    margin: 0 auto !important;
  }
}
@media (min-width: 1000px) and (max-width: 1300px) {
  .eczane-Left-ad {
    width: 200% !important;
  }
}

.tab-buttons {
  display: flex !important;
  gap: 10px !important;
  margin-bottom: 15px !important;
}

.tab-buttons button {
  padding: 10px 20px !important;
  cursor: pointer !important;
}

.tab-buttons button.active {
  color: white !important;
  background-color: #b40505 !important;
}
.tab-buttons button.hover {
  color: white !important;
  background-color: #b40505 !important;
}
