.haberIciRelated {
  justify-content: flex-start !important;
  align-items: flex-start !important;
  display: flex !important;
  padding: 0 !important;
  max-width: 100% !important;
  min-width: none !important;
  height: 100% !important;
}
.haberIciRelated-col {
  max-width: 100% !important;
  height: 100% !important;
}
.haberIciRelated-col .relatedItem .relatedImage {
  aspect-ratio: 728 / 90 !important;
  max-width: 100%;
  /* width: auto; */
  height: auto;
  display: inline-block;
  vertical-align: middle;
}
