body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", "Open Sans", Arial,
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* slick-carousel stil dosyalarını ekleyin */
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

/* Add global styles */
.container {
  width: min(100% - 2rem, 1200px);
  margin-inline: auto;
  padding: 0 1rem;
}

/* zoom handling */
@viewport {
  width: device-width;
  zoom: 1;
}

/* Base font size for zoom */
html {
  font-size: 16px; /* Base size */
  -webkit-text-size-adjust: 100%;
  text-size-adjust: 100%;
}

/* Prevent overflow */
body {
  overflow-x: hidden;
  max-width: 100vw;
}

img {
  max-width: 100%;
  height: auto;
}
